import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {
  public withOrWithoutTaxAddressOptions: any[] = [
    {value: 'all', label: 'Todos'},
    {value: 'with', label: 'Com endereço fiscal'},
    {value: 'without', label: 'Sem endereço fiscal'},
  ];
}
